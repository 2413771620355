import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../theme'
import LogoStampBanner from '../assets/logo-stamp-beige.svg'

const PercentageIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 72.314 88.89"
  >
    <path
      id="Tracé_507"
      data-name="Tracé 507"
      d="M176.773,75.771a23.519,23.519,0,0,0-5.3-7.623,24.3,24.3,0,0,0-8.046-5.065,27.37,27.37,0,0,0-10.057-1.824c-.491,0-.978.013-1.462.036l23.534-40.732-18.846-.008a9.612,9.612,0,0,1-1.253,1.551,8.972,8.972,0,0,1-6.263,2.6,8.608,8.608,0,0,1-3.428-.685,8.891,8.891,0,0,1-2.833-1.917,9.524,9.524,0,0,1-1.255-1.551H124.439a22.407,22.407,0,0,0,1.477,4.792,23.428,23.428,0,0,0,5.3,7.621,24.289,24.289,0,0,0,8.046,5.065,25.25,25.25,0,0,0,6.5,1.621c-14.866,25.887-39.386,68.925-39.386,68.925h18.207l6.548-11.332a23.2,23.2,0,0,0,4.132,5.309,24.25,24.25,0,0,0,8.046,5.065,28.633,28.633,0,0,0,20.113,0,24.269,24.269,0,0,0,8.046-5.065,24.353,24.353,0,0,0,5.3-26.786ZM161.6,88.866a9.643,9.643,0,0,1-1.966,2.829,8.847,8.847,0,0,1-2.88,1.917,8.712,8.712,0,0,1-3.383.685,8.608,8.608,0,0,1-3.428-.685,8.953,8.953,0,0,1-2.833-1.917,9.608,9.608,0,0,1-1.966-2.829,8.8,8.8,0,0,1,0-7.027,9.632,9.632,0,0,1,1.966-2.829,8.953,8.953,0,0,1,2.833-1.917,8.639,8.639,0,0,1,3.428-.683,8.744,8.744,0,0,1,3.383.683,8.847,8.847,0,0,1,2.88,1.917,9.668,9.668,0,0,1,1.966,2.829,8.8,8.8,0,0,1,0,7.027Z"
      transform="translate(-106.378 -20.555)"
      fill={color}
    />
  </svg>
)

const DoubleBanner = ({ left, right }) => (
  <StyledDoubleBanner left={left} right={right}>
    <img src={LogoStampBanner} className="logo-stamp-banner" alt="" />
    <div className="left-banner">
      <div className="content">
        <div className="percentage">
          100
          <div className="percentage-icon">
            <PercentageIcon color={left.textColor} />
          </div>
        </div>
        <p className="title">{left.title}</p>
        <span className="shape"></span>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: left.subTitle }}
        ></p>
      </div>
    </div>
    <div className="right-banner">
      <div className="content">
        <div className="percentage">
          100
          <div className="percentage-icon">
            <PercentageIcon color={right.textColor} />
          </div>
        </div>
        <p className="title">{right.title}</p>
        <span className="shape"></span>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: right.subTitle }}
        ></p>
      </div>
    </div>
  </StyledDoubleBanner>
)

const StyledDoubleBanner = styled.section`
  position: relative;
  width: 100%;
  height: 480px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;

  .logo-stamp-banner {
    position: absolute;
    z-index: 1;
    top: 25%;
    left: 47%;
  }

  .left-banner {
    clip-path: polygon(0 0, 100% 0, calc(100% - 300px) 100%, 0 100%);
    padding-left: 100px;
    background: ${props => props.left.backgroundColor};
    color: ${props => props.left.textColor};
    .shape {
      background-color: ${props => props.left.textColor};
    }
  }

  .left-banner,
  .right-banner {
    width: calc(50% + 150px);
    height: 100%;
    padding: 40px 0;
    position: relative;
    display: flex;

    .content {
      max-width: 600px;
      margin: auto 200px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .percentage {
        font-family: Centra Bold;
        font-size: 90px;
        line-height: 80px;
        display: flex;

        .percentage-icon {
          height: 48px;
          width: 43px;
        }
      }
      .title {
        margin-top: 3px;
        font-family: Bely;
        font-size: 30px;
        text-transform: uppercase;
      }
      .shape {
        width: 23px;
        height: 29px;
        clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
        margin: 37px auto;
      }
      .description {
        color: ${colors.brand.black};
        font-size: 20px;
      }
    }
  }
  .left-banner {
    clip-path: polygon(0 0, 100% 0, calc(100% - 300px) 100%, 0 100%);
    padding-left: 100px;
    background: ${props => props.left.backgroundColor};
    color: ${props => props.left.textColor};
    .shape {
      background-color: ${props => props.left.textColor};
    }
  }
  .right-banner {
    position: absolute;
    right: 0;
    clip-path: polygon(300px 0, 100% 0, 100% 100%, 0 100%);
    padding-right: 100px;
    background-color: ${colors.brand.beige};
    color: ${props => props.right.textColor};

    .content {
      margin: auto 0 auto 250px;
    }
    .shape {
      background-color: ${props => props.right.textColor};
    }
  }

  @media (min-width: 2300px) {
    .content {
      clip-path: polygon(22% 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  @media (max-width: 1800px) {
    .logo-stamp-banner {
      left: 48%;
    }
  }

  @media (max-width: 1500px) {
    .logo-stamp-banner {
      left: 48%;
    }
    .content {
      width: 75%;
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 120px;
      }
    }
  }

  @media (max-width: 1400px) {
    .content {
      width: 72%;
      clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 100px;
      }
    }
  }

  @media (max-width: 1200px) {
    .logo-stamp-banner {
      left: 46%;
    }
    .content {
      max-width: 500px;
      clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 60px;
      }
    }
  }

  @media (max-width: 930px) {
    .content {
      width: 100%;
      clip-path: unset;

      .content {
        margin: auto;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}px) and (max-width: 1600px) {
    .left-banner,
    .right-banner {
      .content {
        max-width: 600px;

        .percentage {
          .percentage-icon {
            height: 48px;
            width: 48px;
          }
        }
        .description {
          width: 80%;
          font-size: 19px;
        }
      }
    }
    .left-banner {
      padding: 60px 40px 60px 0;
    }
    .right-banner {
      padding: 60px 0 60px 40px;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    height: 545px;

    .logo-stamp-banner {
      width: 90px;
      height: 90px;
      top: calc(51% - 45px);
      left: 72%;
    }
    .left-banner {
      clip-path: unset;
    }
    .left-banner,
    .right-banner {
      width: 100%;
      padding: 22px 30px;
      display: block;

      .content {
        margin: auto;

        .percentage {
          font-family: Centra Bold;
          font-size: 65px;
          line-height: 60px;

          .percentage-icon {
            height: 38px;
            width: 33px;
          }
        }
        .title {
          font-family: Bely;
          font-size: 20px;
          text-transform: uppercase;
        }
        .shape {
          width: 17px;
          height: 20px;
          background-color: ${colors.brand.beige};
          clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
          margin: 10px auto;
        }
        .description {
          color: ${colors.brand.black};
          font-size: 12px;
        }
      }
    }
    .right-banner {
      position: absolute;
      top: 50%;
      height: 50%;
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
      .content {
        padding-top: 15px;
      }
    }
  }
`

export default DoubleBanner
export { StyledDoubleBanner, PercentageIcon }
