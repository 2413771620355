import React from 'react'
import styled from 'styled-components'

const Shape = ({ shapeStyle }) => (
  <StyledShape
    style={shapeStyle}
    className={shapeStyle.clipPath && 'clip-path'}
  ></StyledShape>
)

const StyledShape = styled.section`
  z-index: 1;

  &.clip-path {
    clip-path: polygon(0 0, 25% 0, 100% 100%, 75% 100%);
  }
`

export default Shape
export { StyledShape }
