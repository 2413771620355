import React, { useState } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { borders, colors, transitions, breakpoints } from '../theme'

const MailchimpForm = props => {
  const [email, setEmail] = useState('')
  const [hint, setHint] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    addToMailchimp(email)
      .then(data => {
        let msg
        if (
          data.result === 'success' ||
          data.msg.indexOf('is already subscribed') !== -1
        ) {
          msg = 'Promis on pensera à vous'
        } else if (
          data.msg.indexOf('too many recent signup requests') !== -1 ||
          data.msg.indexOf(
            'Too many subscribe attempts for this email address',
          ) !== -1
        ) {
          msg =
            'Ok, ok vous êtes enthousiastes ! Et bien voici un code Promo pour vous: SIGNMEUP'
        } else {
          msg = 'Oops re-essayez'
        }
        setHint(msg)
      })
      .catch(() => {})
  }
  return (
    <StyledMailchimpForm hint={hint} onSubmit={handleSubmit} {...props}>
      <input
        className="email"
        type="email"
        placeholder="votre adresse mail"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <button className="signup" type="submit">
        <i>&rarr;</i>
      </button>
    </StyledMailchimpForm>
  )
}

const StyledMailchimpForm = styled.form`

    &::before {
      content: '${({ hint }) => hint || ''}';
      position: absolute;
      font-size: 0.9rem;
      max-width: 450px;
      color: ${colors.warning};
      top: -20px;
    }

    position: relative;
    height: 66px;
    background: #fff;
    border: 1px solid ${borders.color.light};
    border-radius: 37px;
    flex-grow: 1;
    display: flex;
    font-size: 19px;
    color: #383838;

    input,
    button {
      height: 66px;
      border: none;
      background: transparent;
      box-shadow: none;
      outline: none;
    }

  input {
    padding: 20px 32px 20px 39px;
    flex-grow: 1;

    ::placeholder {
      color: #383838;
      font-size: 19px;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: #383838;
      font-size: 19px;
      opacity: 1;
    }
    ::-webkit-input-placeholder {
      color: #383838;
      font-size: 19px;
      opacity: 1;
    }
  }

  button {
    color: ${colors.brand.normal};
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 15px;
    cursor: pointer;
    transition: ${transitions.all};

    i {
      font-style: normal;
      display: inline-block;
      font-size: 20px;
      color: black;
      transition: color 150ms ease-in-out;
    }

    &:hover i {
      color: ${colors.brand.normal};
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    height: 58px;

    input {
      height: 58px;
      padding: 20px 32px 20px 39px;
      font-size: 14px;

      ::placeholder {
        color: #383838;
        font-size: 14px;
        opacity: 1;
      }
      :-ms-input-placeholder {
        color: #383838;
        font-size: 14px;
        opacity: 1;
      }
      ::-webkit-input-placeholder {
        color: #383838;
        font-size: 14px;
        opacity: 1;
      }
    }

    button {
      height: 58px;
    }
  }
`

export default MailchimpForm
export { StyledMailchimpForm }
