import { Logo, StyledLogo, Button, StyledButton } from '@monbanquet/crumble'
import React from 'react'
import styled from 'styled-components'
import Link from '../components/Link'
import MailchimpForm from '../components/MailchimpForm'
import { breakpoints, colors, transitions, homeLayout } from '../theme'
import { PercentageIcon } from './DoubleBanner'
import Shape, { StyledShape } from './Shape'

class Footer extends React.Component {
  render() {
    return (
      <StyledFooter {...this.props}>
        <div className="footer-container">
          <div className="desktop">
            <Shape
              shapeStyle={{
                width: '400px',
                height: '400px',
                right: '150%',
                top: '200%',
                borderRadius: '50%',
                border: '145px solid ' + colors.secondary.yellow,
                position: 'absolute',
                bottom: '-230px',
                left: '-250px',
              }}
            />
            <Shape
              className="desktop"
              shapeStyle={{
                width: '400px',
                height: '200px',
                border: '140px solid ' + colors.secondary.yellow,
                borderBottom: 'none',
                borderTopLeftRadius: '400px',
                borderTopRightRadius: '400px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                position: 'absolute',
                top: '25px',
                right: '-220px',
                zIndex: '0',
              }}
            />
          </div>
          <div className="tablet">
            <Shape
              shapeStyle={{
                width: '220px',
                height: '220px',
                borderRadius: '50%',
                border: '70px solid ' + colors.secondary.yellow,
                position: 'absolute',
                top: '0',
                left: '-180px',
              }}
            />
            <Shape
              className="desktop"
              shapeStyle={{
                width: '200px',
                height: '100px',
                border: '70px solid ' + colors.secondary.yellow,
                borderBottom: 'none',
                borderTopLeftRadius: '200px',
                borderTopRightRadius: '200px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                position: 'absolute',
                bottom: '50px',
                right: '-50px',
                zIndex: '0',
              }}
            />
          </div>

          <div className="footer-columns">
            <div className="left-column">
              <Logo />
              <ul>
                <li>
                  <Link
                    to={process.env.GATSBY_BLOG_PAGE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.GATSBY_FACEBOOK_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.GATSBY_INSTAGRAM_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.GATSBY_LINKEDIN_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </Link>
                </li>
                <li>
                  <Link to="/traiteurs-paris" target="_blank">
                    Traiteurs Paris
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mobile-separator"></div>
            <div className="middle-column"></div>
            <div className="mobile-separator"></div>
            <div className="right-column">
              <MailchimpForm />
              <div className="percentage">
                <p className="number">100</p>
                <div className="percentage-icon">
                  <PercentageIcon color={colors.brand.normal} />
                </div>
                <div className="label">
                  <p>fait maison</p>
                  <p>responsable</p>
                  <p>réactif</p>
                </div>
              </div>
            </div>
            <div className="links-mobile">
              <Link
                to={process.env.GATSBY_BLOG_PAGE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </Link>
              <span>|</span>
              <Link
                to={process.env.GATSBY_JOB_PAGE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Jobs
              </Link>
              <span>|</span>
              {/* 
              <Link to="/presse" target="_blank">
                Presse
              </Link>
              <span>|</span>
              */}
              <Link
                to={process.env.GATSBY_FACEBOOK_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </Link>
              <span>|</span>
              <Link
                to={process.env.GATSBY_INSTAGRAM_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </Link>
              <span>|</span>
              <Link to="/traiteurs-paris" target="_blank">
                Traiteurs Paris
              </Link>
            </div>
          </div>
          <div className="bottom-row">
            <Link to="/cgv">CGV</Link>
            <span> - </span>
            <Link to="/mentions-legales">Mentions Légales</Link>
          </div>
        </div>
      </StyledFooter>
    )
  }
}

const StyledFooter = styled.footer`
  position: relative;
  background: ${colors.brand.beige};
  padding: 90px 0 20px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
   z-index: 0;
  .tablet ${StyledShape} {
    display: none;
  }

  .footer-container {
    width: ${homeLayout.width};
    margin: auto;
    position: relative;

    .footer-columns {
      display: flex;
      z-index: 1;
      
      .left-column {
        width: 20%;
        margin-right: 5%;
        z-index: 0;

        ul {
          display: flex;
          flex-direction: column;
          margin: 20px 0;

          li {
            letter-spacing: 0.2px;
            font-size: 0.9rem;
            color: ${colors.text.light};
          }
        }

        ${StyledLogo} {
          height: 44px;
          margin-right: auto;
        }
      }

      .middle-column {
        width: 35%;
        margin-right: 5%;
        font-size: 19px;
        z-index: 1;

        .discover-sponsorship {
          margin-bottom: 20px;

          span {
            color: ${colors.brand.green};
          }
        }

        .gift {
          font-weight: bold;
        } 

        ${StyledButton} {
          margin-top: 26px;
          background-color: ${colors.secondary.yellow};
          color: ${colors.brand.black};
          height: 66px;
          font-size: 19px;
          padding: 22px 64px;
          text-transform: none;
        }
      }

      .right-column {
        width: 35%;
        z-index: 0;

        .percentage {
          font-family: Centra Bold;
          margin-top: 38px;
          display: flex;
          align-items: center;

          .percentage-icon {
            height: 62px;
            width: 50px;
          }
        }

          .number {
            font-size: 92px;
            color: ${colors.brand.normal};
          }

          .label {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            margin-left: 22px;

            p:not(:last-child) {
              margin-bottom: 6px;
            }
          }
        }
      }
    }

    .links-mobile {
      display: none;
    }

    .bottom-row {
      margin-top: 90px;
      text-align: center;
      font-size: 13px;

      a {
        transition: ${transitions.all};
        &:hover,
        &:focus {
          color: ${colors.brand.normal};
        }
      }
    }
  }

  @media (min-width: 780px) {
    .mobile-separator {
      display: none;
    }
  }
  @media (min-width: ${breakpoints.tablet}px) and (max-width: 1200px) {
     .footer-container .footer-columns .middle-column {
      ${StyledButton} {
        padding: 20px;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 780px) {
    .tablet ${StyledShape} {
      display: block;
    }
    .desktop ${StyledShape} {
      display: none;
    }
    padding: 40px 30px 20px;
    .footer-container {
      width: 100%;
      text-align: center;
      
      .footer-columns {
        flex-direction: column;
        
        .mobile-separator {
          height: 12px;
          width: 12px;
          background-color: ${colors.brand.green};
          border-radius: 50%;
          margin: 30px auto;
          z-index: 1;
        }

        .left-column {
          ${StyledLogo} {
            height: 30px;
          }
          width: 100%;
          ul {
            display: none;
          }
        }

        .middle-column {
          width: 100%;

          .gift {
            margin-top: 5px;
            font-size: 16px;
            line-height: 25px;
          }

          .discover-sponsorship {
            font-size: 14px;

            span {
              font-weight: bold;
            }
          }

          ${StyledButton} {
            margin-top: 15px;
            height: 45px;
            font-size: 14px;
            padding: 15px 50px;
          }
        }

        .right-column {
          width: 100%;

          .percentage {
            display: none;
          }
        }

        .links-mobile {
          margin: 57px auto 0;
          display: flex;
          font-size: 13px;
          z-index: 0;
          
          > a {
            &:not(:first-child) {
              margin-left: 3px;
            }

            &:not(:last-child) {
              margin-right: 3px;
            }
          }
        }
      }
      .bottom-row {
        margin-top: 15px;
        font-size: 11px;
      }
  }
`

export default Footer
export { StyledFooter }

// export const FooterFragment = graphql`
//   fragment FooterFragment on ContentfulHomePage {}
// `
